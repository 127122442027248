.sidebarNav{
    //All navs
}

.css-ayshjd{
    overflow-x: hidden ;
    // ::-webkit-scrollbar-thumb{
    //     display: none !important;
    //     height: 0 !important;
    //     width: 0;
    // }
}

