.comm_form3 {
  font-family: "Source Sans Pro", sans-serif;
  font-size: 14px;
  margin: 30px auto;
  color: #5b5b5c;
  padding: 10px;
  border-radius: 5px;

  .range-heading {
    text-align: center;
    position: relative;

    h3 {
      margin-bottom: 20px;
      white-space: pre-line;
    }

    .caps {
      text-transform: uppercase;
    }
  }

  .text-center {
    text-align: center;
  }

  .range-data {
    margin-top: 20px;

    ol {
      list-style: none;

      .spacer {
        margin-bottom: 20px;

        p {
          margin: 5px 0;
        }

        table {
          width: 100%;
          border-collapse: collapse;
          margin-top: 10px;

          td,
          th {
            border: 1px solid #000;
            padding: 8px;
            text-align: left;
          }
        }

        .college_div {
          margin-top: 20px;
        }

        .other_information {
          font-weight: bold;
        }
      }
    }
  }
}

.affilates {
  font-size: 14px;

  .profile-display {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-right: 40px;
  }
}

.align-icon {
  display: inline-block;
  vertical-align: middle;
}

.status {
  position: absolute;
  top: 0;
  right: 0;
}

.state {
  color: rgb(32, 140, 212);
}
#demo-p-2 {
  padding: 20px;
  background-color: #f5f5f5;
}

#demo-p-2 h3 {
  color: #333;
}

.discount {
  color: #990000;
  font-weight: bold;
}

.table-bordered.whitebg {
  border: 1px solid #ddd;
  width: 100%;
  margin-top: 20px;
}

.table-bordered.whitebg .blueshade th {
  background-color: #337ab7;
  color: #fff;
}

.table-bordered.whitebg td {
  border: 1px solid #ddd;
  padding: 8px;
}

.final_total {
  font-weight: bold;
  color: #337ab7;
}

.full_width {
  width: 100%;
  margin-top: 20px;
}

.table-bordered.whitebg .blueshade td {
  border: none;
}

.blueshade {
  background-color: #fff;
}
